<template>
  <NuxtLink
    :to="localePath(productLink)"
    class="block item-container border-gray border-solid relative w-[auto] h-[283px] px-2.5 py-5 hover:cursor-pointer text-green-swamp hover:text-orange min-w-[189px] max-w-[210px]"
  >
    <ProductListTags
      :tags="variant?.productTags || product?.productTags"
      class="product-tag"
      @click.prevent.stop
    />
    <div class="w-[130px] h-[118px] mx-auto mb-5 flex justify-center">
      <nuxt-img
        v-if="image && !imageError"
        :src="image"
        class="h-full m-auto object-contain"
        :height="image?.includes('no-image.webp') ? 50 : 118"
        width="130"
        loading="lazy"
        @error="() => (imageError = true)"
      />
      <nuxt-img
        v-else
        :src="NO_IMAGE_PATH"
        class="m-auto object-contain"
        height="50"
        width="130"
        loading="lazy"
      />
    </div>

    <div class="min-h-[60px]">
      <div class="font-semibold text-xs line-clamp-3">
        {{ variant.name }}
      </div>
    </div>

    <div
      class="mt-2 text-green-swamp space-x-1"
      :class="[variant && variant.getDiscount() ? 'text-xs' : 'text-[15px]']"
    >
      <span v-if="variant?.price === null">
        {{ $t('without_price') }}
      </span>
      <span
        :class="[
          variant && variant.getDiscount() ? 'line-through' : 'font-bold',
          variant && variant.inStock
            ? 'decoration-primary'
            : 'decoration-gray-mud'
        ]"
      >
        {{ variant && variant.getFormattedOriginalPrice() }}
      </span>

      <span
        v-if="variant && variant.getDiscount()"
        class="px-1.5"
        :class="variant && variant.inStock ? 'bg-red-hot/20' : 'bg-black/20'"
      >
        -{{ variant && variant.getDiscount() }}%
      </span>
    </div>
    <div
      v-if="variant && variant?.getDiscount && variant?.getDiscount()"
      class="text-[15px] font-bold"
      :class="[variant.inStock ? 'text-primary' : 'text-gray-mud']"
    >
      {{ variant.getFormattedPrice() }}
    </div>
  </NuxtLink>
</template>

<script setup>
import ProductVariantEntity from '~/entities/ProductVariant.entity'
import ProductEntity from '~/entities/Product.entity'
const { locale } = useI18n()
const imageError = ref(false)

const props = defineProps({
  product: {
    type: Object,
    default: () => ({})
  },
  isWatchedProduct: {
    type: Boolean,
    default: () => false
  },
  isImagePath: {
    type: Boolean,
    default: false
  }
})

const product = computed(() =>
  props.isWatchedProduct
    ? props.product?.product
    : new ProductEntity(props.product)
)

const variant = computed(() => {
  if (props.isWatchedProduct) {
    return new ProductVariantEntity(props.product)
  }
  // return props.product?.getVariant && props.product?.getVariant()
  return product.value?.getVariant && product.value?.getVariant()
})

const productLink = computed(() => {
  return ProductVariantEntity.buildProductLink(
    product.value || variant.value,
    variant.value,
    '',
    locale.value
  )
})

const dataTitle = computed(
  () =>
    'variant-image-preview-' +
    ((variant && variant.value?.code) || props.product?.code)
)

const { data: image } = useAsyncData(
  dataTitle.value,
  () => {
    if (props.isWatchedProduct || props.isImagePath) {
      return variant.value?.images[0]?.path || NO_IMAGE_PATH
    }
    return variant.value?.fetchMainImage(
      PRODUCT_IMAGE_FILTER.PRODUCT_SMALL_THUMBNAIL
    )
  },
  {
    watch: [dataTitle, variant]
  }
)
</script>

<style scoped>
.item-container:hover {
  box-shadow: 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02), 0 10px 40px rgba(38, 38, 38, 0.05),
    0 2px 4px rgba(38, 38, 38, 0.02);
  background: #ffffff;
}
.product-tag {
  margin-top: -10px;
}
</style>
