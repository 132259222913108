import api from '../api.client'

const path = '/api/v2/shop/shipping-methods'

export default {
  getAll: ({ shipmentId, tokenValue }, options = {}) =>
    api({ ...options }).get(
      `${path}?shipmentId=${shipmentId}&tokenValue=${tokenValue}`
    ),
  getOne: (
    {
      city,
      code,
      query,
      cityRef,
      shipmentId,
      tokenValue,
      isOversizedOrder = 0
    },
    options = {}
  ) => {
    return api({ auth: false, ...options }).get(`${path}/${code}`, {
      city,
      query,
      cityRef,
      shipmentId,
      tokenValue,
      isOversizedOrder
    })
  }
}
