import {
  TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
  CUSTOMER_ID_COOKIE_KEY,
  CART_TOKEN_COOKIE_KEY,
  CART_PREORDER_TOKEN_COOKIE_KEY,
  REMEMBER_CUSTOMER_COOKIE_KEY,
  REFRESH_TOKEN_STORE_COOKIE_KEY,
  TOKEN_STORE_COOKIE_KEY,
  SC_TOKEN_COOKIE_KEY,
  GA_SESSION_ID_KEY,
  GA_CLIENT_ID_KEY
} from '~~/utils/constants'

const cookieOptions = () => {
  return {
    httpOnly: false,
    secure: 'true',
    sameSite: 'none'
  }
}

const cookieSCOptions = () => {
  const url = useRequestURL()
  return {
    httpOnly: false,
    secure: 'true',
    sameSite: 'none',
    domain: '.' + url?.hostname
  }
}

export function getToken() {
  return useCookie(TOKEN_COOKIE_KEY).value || null
}

export function setToken(token, options = {}) {
  const tokenCookie = useCookie(TOKEN_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  tokenCookie.value = token
}

export function getTokenStore() {
  return useCookie(TOKEN_STORE_COOKIE_KEY).value || null
}

export function setTokenStore(token, options = {}) {
  const tokenStoreCookie = useCookie(TOKEN_STORE_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  tokenStoreCookie.value = token
}

export function getRefreshToken() {
  return useCookie(REFRESH_TOKEN_COOKIE_KEY).value || null
}

export function setRefreshToken(token, options = {}) {
  const refreshTokenCookie = useCookie(REFRESH_TOKEN_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  refreshTokenCookie.value = token
}

export function getRefreshTokenStore() {
  return useCookie(REFRESH_TOKEN_STORE_COOKIE_KEY).value || null
}

export function setRefreshTokenStore(token, options = {}) {
  const refreshTokenStoreCookie = useCookie(REFRESH_TOKEN_STORE_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  refreshTokenStoreCookie.value = token
}

export function getCustomerId() {
  return useCookie(CUSTOMER_ID_COOKIE_KEY).value || null
}

export function setCustomerId(token, options = {}) {
  const customerIdCookie = useCookie(CUSTOMER_ID_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  customerIdCookie.value = token
}

export function getCartToken() {
  return useCookie(CART_TOKEN_COOKIE_KEY).value || null
}

export function setCartToken(token, options = {}) {
  const customerIdCookie = useCookie(CART_TOKEN_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  customerIdCookie.value = token
}

export function getScToken() {
  return useCookie(SC_TOKEN_COOKIE_KEY).value || null
}

export function setScToken(token, options = {}) {
  const scCookie = useCookie(SC_TOKEN_COOKIE_KEY, {
    ...cookieSCOptions(),
    ...options
  })
  scCookie.value = token
}

export function getPreorderCartToken() {
  return useCookie(CART_PREORDER_TOKEN_COOKIE_KEY).value || null
}

export function setPreorderCartToken(token, options = {}) {
  const customerIdCookie = useCookie(CART_PREORDER_TOKEN_COOKIE_KEY, {
    ...cookieOptions(),
    ...options
  })
  customerIdCookie.value = token
}

export function removeToken() {
  useCookie(TOKEN_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function removeRefreshToken() {
  useCookie(REFRESH_TOKEN_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function removeTokenStore() {
  useCookie(TOKEN_STORE_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function removeRefreshTokenStore() {
  useCookie(REFRESH_TOKEN_STORE_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function removeCustomerId() {
  useCookie(CUSTOMER_ID_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function getCookieOptions(isRemember) {
  return {
    ...cookieOptions(),
    expires: isRemember ? new Date('2099-12-31') : null
  }
}

export function getSCCookieOptions(isRemember) {
  return {
    ...cookieSCOptions(),
    expires: isRemember ? new Date('2099-12-31') : null
  }
}

export function getIsRememberCustomer() {
  return useCookie(REMEMBER_CUSTOMER_COOKIE_KEY).value || null
}

export function setIsRememberCustomer(value) {
  const isRememberCustomerCookie = useCookie(REMEMBER_CUSTOMER_COOKIE_KEY, {
    ...cookieOptions(),
    expires: new Date('2099-12-31')
  })
  isRememberCustomerCookie.value = value
}

export function removeIsRememberCustomer() {
  useCookie(REMEMBER_CUSTOMER_COOKIE_KEY, {
    ...cookieOptions()
  }).value = null
}

export function removeScToken() {
  useCookie(SC_TOKEN_COOKIE_KEY, {
    ...cookieSCOptions()
  }).value = null
}

export function setGaSessionId(value) {
  const gaSessionId = useCookie(GA_SESSION_ID_KEY, {
    ...cookieOptions()
  })
  gaSessionId.value = value
}

export function setGaClientId(value) {
  const gaClientId = useCookie(GA_CLIENT_ID_KEY, {
    ...cookieOptions()
  })
  gaClientId.value = value
}

export function removeAllCookies() {
  removeScToken()
  removeToken()
  removeRefreshToken()
  removeCustomerId()
  removeTokenStore()
  removeRefreshTokenStore()
  removeIsRememberCustomer()
}
